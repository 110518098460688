<div class="topo-interna">
  <!-- Navigation -->
  <nav class="navbar navbar-fixed-top" role="navigation" aria-label="Menu do sistema">
    <div class="container">
      <!-- Brand and toggle get grouped for better mobile display -->
      <div class="navbar-header">
        <button type="button" class="navbar-toggle" data-toggle="collapse" data-target="#collapse-1"
                (click)="clickMenuMobile();">
          <span class="sr-only">{{ 'system.toggleMenu' | translate }}</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <div class="navbar-brand">
          <a routerLink="/geral/inicial" class="link-home">
            <img ngSrc="assets/img/logo-viajabox.webp" [alt]="'system.logomarca' | translate"
                 height="50" width="157" id="logomarca" [priority]="true">
          </a>
        </div>
        <div class="bn">
          <div class="dropdown">
            <button class="btn btn-default dropdown-toggle" type="button" id="nc"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <fa-icon [icon]="archive"></fa-icon>
              <span class="badge" *ngIf="globals?.somaAlertaCaixa">{{ globals?.somaAlertaCaixa }}</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="nc">
              <div class="titulop">{{ 'menu.caixasRecebidas' | translate }}</div>
              <li *ngFor="let notificacao of globals?.listaAlertaUsuarioCaixa">
                <a>
                  <p class="header-notificacao">
                    <span class="titulo">{{ notificacao.tipoAlertaEnum | translate }}</span>
                    <button class="btn-excluir" [title]="'inicial.excluirNotificacao' | translate"
                            (click)="marcarLeituraAlerta(notificacao)">&times;
                    </button>
                  </p>
                  <p class="data">
                    {{ 'inicial.recebidaEm' | translate }}
                    {{ notificacao.dtInclusao | localizedDateTime }}</p>
                  <button class="link" (click)="verificarCaixaRecebida(notificacao)">
                    {{ 'inicial.verificar' | translate }}
                  </button>
                </a>
              </li>
              <li *ngIf="globals?.listaAlertaUsuarioCaixa?.length === 0">
                <a>{{ 'system.no.results.found' | translate }}</a>
              </li>
            </ul>
          </div>

          <div class="dropdown">
            <button class="btn btn-default dropdown-toggle" type="button" id="notificacoesEstoque"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <fa-icon [icon]="cartShopping"></fa-icon>
              <span class="badge" *ngIf="globals?.somaAlertaEstoque">{{ globals?.somaAlertaEstoque }}</span>
            </button>
            <ul class="dropdown-menu" style="min-width: 360px;" aria-labelledby="notificacoesEstoque">
              <div class="titulop" [innerHTML]="'menu.itensCaixas' | translate"></div>
              <li *ngFor="let notificacao of globals?.listaAlertaEstoque">
                <a>
                  <p class="header-notificacao">
                    <span class="titulo">{{ notificacao.tipoAlertaEnum | translate }}</span>
                    <button class="btn-excluir" [tooltip]="'inicial.excluirNotificacao' | translate"
                            (click)="marcarLeituraAlerta(notificacao)">&times;
                    </button>
                  </p>
                  <p class="data">
                    {{ 'inicial.recebidaEm' | translate }}
                    {{ notificacao.dtInclusao | date: ('global.mascaraDataHora' |translate) }}
                  </p>
                  <button class="link" (click)="verificarItensEstoque(notificacao)">
                    {{ 'inicial.verificar' | translate }}
                  </button>
                </a>
              </li>
              <li *ngIf="globals?.listaAlertaEstoque?.length === 0"><a>{{ 'system.no.results.found' | translate }}</a>
              </li>
            </ul>
          </div>

          <div class="dropdown">
            <button class="btn btn-default dropdown-toggle" type="button" id="notificacoesEnvios"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <fa-icon [icon]="plane"></fa-icon>
              <span class="badge" *ngIf="globals?.somaAlertaEnvio">{{ globals?.somaAlertaEnvio }}</span>
            </button>
            <ul class="dropdown-menu" aria-labelledby="notificacoesEnvios">
              <div class="titulop" [innerHTML]="'menu.enviosCadastrados.titulo' | translate"></div>
              <li *ngFor="let notificacao of globals?.listaAlertaEnvio">
                <a>
                  <p class="header-notificacao">
                    <span class="titulo">{{ notificacao.tipoAlertaEnum | translate }}</span>
                    <button
                      class="btn-excluir" title="{{'inicial.excluirNotificacao' | translate}}"
                      (click)="marcarLeituraAlerta(notificacao)">&times;
                    </button>
                  </p>
                  <p class="data">
                    {{ 'inicial.recebidaEm' | translate }}
                    {{ notificacao.dtInclusao | localizedDateTime }}
                  </p>
                  <button class="link" (click)="verificarEnvios(notificacao)">
                    {{ 'inicial.verificar' | translate }}
                  </button>
                </a>
              </li>
              <li *ngIf="globals?.listaAlertaEnvio?.length === 0"><a>{{ 'system.no.results.found' | translate }}</a>
              </li>
            </ul>
          </div>

          <div class="dropdown">
            <button class="btn btn-default dropdown-toggle" type="button" id="notificacoesAssistida"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
              <fa-icon [icon]="handsHelping"></fa-icon>
              <span class="badge" *ngIf="globals?.somaAlertaCompras">{{ globals?.somaAlertaCompras }}</span>
            </button>
            <ul class="dropdown-menu" style="min-width: 360px;" aria-labelledby="notificacoesAssistida">
              <div class="titulop">{{ 'menu.compraAssistida' | translate }}</div>
              <li *ngFor="let notificacao of globals?.listaAlertaCompras">
                <a>
                  <p class="header-notificacao">
                    <span class="titulo">{{ notificacao.tipoAlertaEnum | translate }}</span>
                    <button class="btn-excluir" [title]="'inicial.excluirNotificacao' | translate"
                            (click)="marcarLeituraAlerta(notificacao)">&times;
                    </button>
                  </p>
                  <p class="data">
                    {{ 'inicial.recebidaEm' | translate }}
                    {{ notificacao.dtInclusao | localizedDateTime }}
                  </p>
                  <button class="link" (click)="verificarCompras(notificacao)">
                    {{ 'inicial.verificar' | translate }}
                  </button>
                </a>
              </li>
              <li *ngIf="globals?.listaAlertaCompras?.length === 0"><a>{{ 'system.no.results.found' | translate }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Collect the nav links, forms, and other content for toggling -->
      <div class="collapse navbar-collapse" id="collapse-1" *ngIf="isMobileView">
        <ul class="nav navbar-nav">
          <span class="mobile">
            <a class="list-group-item pointer" data-remote="true" data-target="#sub_categoria_4_mobile" id="categoria_4"
               data-toggle="collapse" data-parent="#sub_categoria_4_mobile" *ngIf="isAdmin">
              <fa-icon [icon]="cog"></fa-icon>
              {{ 'menu.administrador.titulo' | translate }}
            </a>
            <!-- SUBMENU -->
            <div class="collapse list-group-submenu submenu-mobile" id="sub_categoria_4_mobile" *ngIf="isAdmin">
              <a routerLink="/admin/gerenciar/tudo" *ngIf="isAdmin" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4_mobile"
                 data-toggle="collapse" data-target="#collapse-1" (click)="hideMenu()">
                <fa-icon [icon]="globe"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.titulo' | translate }}
              </a>
              <a routerLink="/admin/cadastrar-caixas" *ngIf="isAdmin" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4_mobile" (click)="hideMenu()"
                 data-toggle="collapse" data-target="#collapse-1">
                <fa-icon [icon]="archive"></fa-icon>
                {{ 'menu.administrador.cadastrarCaixas' | translate }}
              </a>
              <a routerLink="/admin/cadastrar-itens" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4_mobile">
                <fa-icon [icon]="download"></fa-icon>
                {{ 'menu.administrador.cadastrarItens' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-envios" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="book"></fa-icon>
                {{ 'menu.administrador.gerenciarEnvios' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-usuarios" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="users"></fa-icon>
                {{ 'menu.administrador.gerenciarUsuarios' | translate }}
              </a>
              <a routerLink="/admin/notificar-usuarios" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="notify"></fa-icon>
                {{ 'menu.administrador.notificarUsuarios' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-compra-assistida" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="assisted"></fa-icon>
                {{ 'menu.administrador.gerenciarCompraAssistida' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-devolucao" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="returned"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.gerenciarDevolucao.titulo' | translate }}
              </a>
              <a routerLink="/admin/transferir-caixa" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="route"></fa-icon>
                {{ 'menu.administrador.vincularCaixaViajaboxPrateleira.titulo' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-data-cadastramento" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="clock"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.gerenciarDataCadastramento' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-cupom" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="coupon"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.gerenciarCupom.titulo' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-consulta-campanha" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="campain"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.gerenciarCampanha.tituloPagina' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-creditos" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="creditCard"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.gerenciarCreditos.titulo' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-itens-vencidos" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="bookSkull"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.gerenciarItensVencidos.titulo' | translate }}
              </a>
              <a routerLink="/admin/confere-saida" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="checklist"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.confere.saida.titulo' | translate }}
              </a>
              <a routerLink="/admin/transfere-itens" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="boxesPacking"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.transferirItens.titulo' | translate }}
              </a>
              <a routerLink="/admin/transfere-caixas" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="dolly"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.transferirCaixas' | translate }}
              </a>
              <a routerLink="/admin/email-marketing" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="email"></fa-icon>
                {{ 'email.marketing.titulo' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-estante" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="cubes"></fa-icon>
                {{ 'menu.administrador.gerenciarEstante.titulo' | translate }}
              </a>
              <a routerLink="/admin/visualizar-alertas" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="emailBulk"></fa-icon>
                {{ 'menu.administrador.visualizarAlertas.titulo' | translate }}
              </a>
              <a routerLink="/calculadora-importacao/consulta" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="calculator"></fa-icon>
                {{ 'calculadoraImportacao.titulo' | translate }}
              </a>
              <a routerLink="/admin/gerenciar-exemplo-declaracao" *ngIf="isAdmin" class="list-group-item sub-item"
                 (click)="hideMenu()" data-parent="#sub_categoria_4">
                <fa-icon [icon]="book"></fa-icon>
                {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.titulo' | translate }}
              </a>
            </div>
            <a routerLink="/geral/inicial" class="list-group-item" data-toggle="collapse" data-target="#collapse-1"
               (click)="hideMenu()">
              <fa-icon [icon]="home"></fa-icon>
              {{ 'menu.enderecoEUA' | translate }}
            </a>
            <a routerLink="/envios/caixas-recebidas" class="list-group-item" data-toggle="collapse"
               (click)="hideMenu()" data-target="#collapse-1">
              <fa-icon [icon]="archive"></fa-icon>
              {{ 'menu.caixasRecebidas' | translate }}
            </a>
            <a routerLink="/envios/itens-estoque" class="list-group-item" data-toggle="collapse"
               (click)="hideMenu()" data-target="#collapse-1">
              <fa-icon [icon]="cartShopping"></fa-icon> <span [innerHTML]="'menu.itensCaixas' | translate"></span>
            </a>
            <a routerLink="/compra-assistida" class="list-group-item" data-toggle="collapse"
               (click)="hideMenu()" data-target="#collapse-1">
              <fa-icon [icon]="shoppingBasket"></fa-icon>
              {{ 'menu.compraAssistida' | translate }}
            </a>
            <a routerLink="/envios/cadastrados" class="list-group-item" data-toggle="collapse"
               (click)="hideMenu()" data-target="#collapse-1">
              <fa-icon [icon]="plane"></fa-icon>
              <span [innerHTML]="'menu.enviosCadastrados.titulo' | translate"></span>
            </a>
            <a routerLink="/geral/lojas" class="list-group-item" data-toggle="collapse"
               (click)="hideMenu()" data-target="#collapse-1">
              <fa-icon [icon]="cartShopping"></fa-icon>
              {{ 'menu.lojas' | translate }}
            </a>
             <a routerLink="/calculadora-importacao/consulta" class="list-group-item" data-toggle="collapse"
                (click)="hideMenu()" data-target="#collapse-1">
              <fa-icon [icon]="calculator"></fa-icon>
               {{ 'calculadoraImportacao.titulo' | translate }}
            </a>
            <a routerLink="/geral/afiliados" class="list-group-item" data-toggle="collapse"
               (click)="hideMenu()" data-target="#collapse-1">
              <fa-icon [icon]="users"></fa-icon>
              {{ 'menu.afiliados' | translate }}
            </a>
            <a routerLink="/geral/exemplo-declaracao" class="list-group-item" data-toggle="collapse"
               (click)="hideMenu()" data-target="#collapse-1">
              <fa-icon [icon]="exemploDeclaracao"></fa-icon>
              {{ 'menu.exemploDeclaracao.titulo' | translate }}
            </a>
            <a routerLink="/geral/imposto-zero" class="list-group-item" data-toggle="collapse"
               (click)="hideMenu()" data-target="#collapse-1">
              <img ngSrc="/assets/img/sales-tax-mini.png" alt="Sales Tax" width="19" height="16">
              {{ 'menu.impostoZero' | translate }}
            </a>
            <a routerLink="/geral/ajuda" class="list-group-item" data-toggle="collapse"
               (click)="hideMenu()" data-target="#collapse-1">
              <fa-icon [icon]="info"></fa-icon>
              {{ 'menu.ajuda.titulo' | translate }}
            </a>
          </span>
        </ul>
      </div>

      <div class="menu-usuario-topo">
        <div class="calculadora">
          <ul style="padding-left: 0; margin-left: 0 !important">
            <li>
              <div class="dropdown-toggle" aria-expanded="false" routerLink="/calculadora-frete"
                   tooltip="Calculadora de taxa de envio" placement="bottom">
                <fa-icon [icon]="calculator"></fa-icon>
              </div>
            </li>
          </ul>
        </div>

        <app-idioma class="cl"></app-idioma>

        <div class="pontos-e-creditos">
          <a data-toggle="modal" data-target="#modalPontuacao" aria-expanded="false">
            {{ pontuacaoUsuario | emptyValue }} {{ 'inicial.pts' | translate }}
          </a>
          <a data-toggle="modal" data-target="#modalCreditos" aria-expanded="false">
            ${{ creditosASeremSacados | moeda: false | emptyValue }}
          </a>
          <div>{{ 'system.topo.suite' | translate }}{{ usuario.idUsuario | emptyValue }}</div>
        </div>

        <ul class="nav navbar-nav">
          <li class="dropdown nome-usuario" *ngIf="usuario" id="menu-usuario">
            <a class="dropdown-toggle" data-toggle="dropdown" aria-expanded="false" (click)="hideMenu()">
              <img *ngIf="!usuario.idArquivo" ngSrc="assets/img/user.webp" alt="Arquivo" width="52" height="52">
              <img *ngIf="usuario.idArquivo" width="52" height="52"
                   [src]="'rest/arquivoRestService/recuperarFotoUsuario/' + usuario.idArquivo" alt="Avatar">
            </a>
            <ul class="dropdown-menu dropdown-limited dropdown-menu-right">
              <div class="dados-usuario">
                <img ngSrc="assets/img/user.webp" *ngIf="!usuario.idArquivo" alt="Arquivo" width="52" height="52">
                <img *ngIf="usuario.idArquivo" width="52" height="52"
                     [src]="'rest/arquivoRestService/recuperarFotoUsuario/' + usuario.idArquivo"
                     alt="Foto do usuário">
                <span class="nome-usuario">{{ usuario.login }}</span>
                <span class="link-foto-usuario">
                  <a class="dropdown-toggle" data-toggle="modal" data-target="#modalImagemFoto" href=""
                     aria-expanded="false">
                    <fa-icon [icon]="camera"></fa-icon>
                    {{ 'inicial.alterarFoto' | translate }}
                  </a>
                </span>
              </div>
              <li>
                <a routerLinkActive="active" routerLink="/geral/minha-conta" class="list-group-item sub-item"
                   data-parent="#sub_categoria_5">
                  {{ 'menu.dadosPessoais' | translate }}
                </a>
              </li>
              <li>
                <a routerLinkActive="active" routerLink="/geral/meus-enderecos" class="list-group-item sub-item"
                   data-parent="#sub_categoria_5">
                  {{ 'menu.meusEnderecos.titulo' | translate }}
                </a>
              </li>
              <li *ngIf="usuario">
                <a id="systemLogout" (click)="logout()" class="list-group-item sub-item">
                  {{ 'menu.logout' | translate }}
                  <fa-icon [icon]="signOut"></fa-icon>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <nav id="mainmenu" class="interno" [class]="isAdmin ? 'navbar admin' : 'navbar'"
       aria-label="Menu interno">
    <div class="borda"></div>
    <div class="borda2"></div>
    <div [class.lista-menu]="!isAdmin">
      <ul class="list-group">
        <li id="menu-admin" class="dropdown" *ngIf="isAdmin">
          <a class="list-group-item dropdown-toggle" data-remote="true" id="categoria_4" data-toggle="dropdown"
             data-parent="#sub_categoria_4" [class.active]="isAdminRoute">
            <fa-icon [icon]="cog"></fa-icon>
            <div class="text-menu">{{ 'menu.administrador.tituloBarra' | translate }}</div>
          </a> <!-- SUBMENU -->
          <ul class="list-group-submenu dropdown-menu" id="sub_categoria_4" *ngIf="isAdmin">
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar/tudo" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/cadastrar-caixas" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.cadastrarCaixas' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/cadastrar-itens" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.cadastrarItens' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar-envios" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarEnvios' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar-usuarios" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarUsuarios' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/notificar-usuarios" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.notificarUsuarios' | translate }}
              </a></li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar-compra-assistida"
                 class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarCompraAssistida' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/transferir-caixa" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.vincularCaixaViajaboxPrateleira.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar-devolucao" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.gerenciarDevolucao.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar-data-cadastramento"
                 class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.gerenciarDataCadastramento' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar-cupom" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.gerenciarCupom.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar-consulta-campanha"
                 class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.gerenciarCampanha.tituloPagina' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar-creditos" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.gerenciarCreditos.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar-itens-vencidos" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.gerenciarItensVencidos.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/confere-saida" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.confere.saida.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/transfere-itens" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.transferirItens.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/transfere-caixas" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.transferirCaixas' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/email-marketing" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'email.marketing.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLinkActive="active" routerLink="/admin/gerenciar-estante" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarEstante.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLink="/admin/visualizar-alertas" class="list-group-item sub-item" data-parent="#sub_categoria_4">
                {{ 'menu.administrador.visualizarAlertas.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLink="/calculadora-importacao/consulta" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'calculadoraImportacao.titulo' | translate }}
              </a>
            </li>
            <li>
              <a routerLink="/admin/gerenciar-exemplo-declaracao" class="list-group-item sub-item"
                 data-parent="#sub_categoria_4">
                {{ 'menu.administrador.gerenciarTudo.gerenciarExemploDeclaracao.titulo' | translate }}
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/geral/inicial" class="list-group-item">
            <fa-icon [icon]="home"></fa-icon>
            <div class="text-menu">{{ 'menu.enderecoEUA' | translate }}</div>
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/envios/caixas-recebidas" class="list-group-item">
            <fa-icon [icon]="archive"></fa-icon>
            <div class="text-menu">{{ 'menu.caixasRecebidasBarra' | translate }}</div>
          </a>
        </li>
        <li>
          <a id="idEstoqueEnvio" routerLinkActive="active" routerLink="/envios/itens-estoque" class="list-group-item">
            <fa-icon [icon]="cartShopping"></fa-icon>
            <div [innerHtml]="'menu.itensCaixasBarra' | translate" class="text-menu"></div>
            <span *ngIf="somaItensEstoque > 0" class="label label-danger num-alerta">
            {{ somaItensEstoque }}
          </span>
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/compra-assistida" class="list-group-item">
            <fa-icon [icon]="handsHelping"></fa-icon>
            <div class="text-menu">{{ 'menu.compraAssistidaBarra' | translate }}</div>
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/envios/cadastrados" class="list-group-item">
            <fa-icon [icon]="plane"></fa-icon>
            <div class="text-menu">{{ 'menu.enviosCadastrados.titulo' | translate }}</div>
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/geral/lojas" class="list-group-item">
            <fa-icon [icon]="shoppingBasket"></fa-icon>
            <div class="text-menu">{{ 'menu.lojas' | translate }}</div>
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/calculadora-importacao/consulta" class="list-group-item">
            <fa-icon [icon]="calculator"></fa-icon>
            <div class="text-menu">{{ 'calculadoraImportacao.titulo' | translate }}</div>
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/geral/afiliados" class="list-group-item">
            <fa-icon [icon]="users"></fa-icon>
            <div class="text-menu">{{ 'menu.afiliados' | translate }}</div>
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/geral/exemplo-declaracao" class="list-group-item">
            <fa-icon [icon]="exemploDeclaracao"></fa-icon>
            <div class="text-menu">{{ 'menu.exemploDeclaracao.titulo' | translate }}</div>
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/geral/imposto-zero" class="list-group-item">
            <img ngSrc="/assets/img/sales-tax.png" alt="Sales Tax" width="40" height="34">
            <div class="text-menu">{{ 'menu.impostoZero' | translate }}</div>
          </a>
        </li>
        <li>
          <a routerLinkActive="active" routerLink="/geral/ajuda" class="list-group-item">
            <fa-icon [icon]="questionCircle"></fa-icon>
            <div class="text-menu">{{ 'menu.ajuda.titulo' | translate }}</div>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
<div class="topo-borda"></div>

<app-foto-usuario modalId="modalImagemFoto" (update)="onChangeFoto($event)"></app-foto-usuario>
<app-pontuacao modalId="modalPontuacao"></app-pontuacao>
<app-creditos modalId="modalCreditos"></app-creditos>
