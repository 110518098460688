import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from "rxjs";
import {ReadonlyService} from "../framework/readonly.service";
import {PaisDto} from "../models/dto/pais.dto";
import {FamilyProdCodeDto} from "../models/dto/family-prod-code.dto";
import {PerfilDto} from "../models/dto/perfil.dto";
import {StatusEnvioDto} from "../models/dto/status-envio.dto";
import {TaxaCobrancaModel} from "../models/taxa-cobranca.model";
import {StatusComprasDto} from "../models/dto/status-compras.dto";
import {TipoOpcaoEnvioDto} from "../models/dto/tipo-opcao-envio.dto";
import {TaxaCobrancaDto} from "../models/dto/taxa-cobranca.dto";
import {OpcaoExtraDto} from "../models/dto/opcao-extra.dto";
import {PesquisaUsuarioDto} from "../models/dto/pesquisa-usuario.dto";
import {PesquisaUsuarioCaixaUploadDto} from "../models/dto/pesquisa-usuario-caixa-upload.dto";
import {PalavraExcluidaDeclaracaoDto} from "../models/dto/palavra-excluida-declaracao.dto";
import {CupomDto} from "../models/dto/cupom.dto";
import {MoedaDto} from "../models/dto/moeda.dto";
import {ViaCepVo} from "../models/vo/via-cep.vo";
import {MessageService} from "./message.service";
import {MoedaPaisDto} from "../models/dto/moeda-pais.dto";
import {DTOModel} from "../framework/dto.model";
import {TipoAlertaDto} from "../models/dto/tipo-alerta.dto";
import {EstadoBrasilDto} from "../models/dto/estado-brasil.dto";
import {EnvioModel} from "../models/envio.model";

@Injectable({
  providedIn: 'root'
})
export class DadosDominioService extends ReadonlyService<any, number> {

  constructor(
    readonly http: HttpClient,
    readonly messageService: MessageService
  ) {
    super('/dadosDominioRestService', http, messageService);
  }

  recuperarListaPais(): Observable<PaisDto> {
    return this.http.get<PaisDto>(`${this.baseURL}/recuperarListaPais`).pipe(
      this.mapError
    );
  }

  recuperarListaEstadoBrasil(): Observable<EstadoBrasilDto> {
    return this.http.get<PaisDto>(`${this.baseURL}/recuperarListaEstadoBrasil`).pipe(
      this.mapError
    );
  }

  recuperarFamilyProdCode(): Observable<FamilyProdCodeDto> {
    return this.http.post<FamilyProdCodeDto>(`${this.baseURL}/recuperarFamilyProdCode`, {}).pipe(
      this.mapError
    );
  }

  recuperarListaPerfilEnum(): Observable<PerfilDto> {
    return this.http.post<PerfilDto>(`${this.baseURL}/recuperarListaPerfilEnum`, {}).pipe(
      this.mapError
    );
  }

  recuperarListaStatusEnvioEnum(): Observable<StatusEnvioDto> {
    return this.http.post<StatusEnvioDto>(`${this.baseURL}/recuperarListaStatusEnvioEnum`, {}).pipe(
      this.mapError
    );
  }

  recuperarListaStatusCompraAssistidaEnum(): Observable<StatusComprasDto> {
    return this.http.post<StatusComprasDto>(`${this.baseURL}/recuperarListaStatusCompraAssistidaEnum`, {}).pipe(
      this.mapError
    );
  }

  recuperarListaTipoOpcaoEnvio(): Observable<TipoOpcaoEnvioDto> {
    return this.http.post<TipoOpcaoEnvioDto>(`${this.baseURL}/recuperarListaTipoOpcaoEnvio`, {}).pipe(
      this.mapError
    );
  }

  consultarTaxaCobrancaPorPesoETipoOpcaoEnvio(taxaCobranca: TaxaCobrancaModel): Observable<TaxaCobrancaDto> {
    if (!taxaCobranca?.tipoOpcaoEnvio) {
      return of({});
    }

    return this.http.post<TaxaCobrancaDto>(`${this.baseURL}/consultarTaxaCobrancaPorPesoETipoOpcaoEnvio`, taxaCobranca).pipe(
      this.mapError
    );
  }

  recuperarListaOpcaoExtra(): Observable<OpcaoExtraDto> {
    return this.http.post<OpcaoExtraDto>(`${this.baseURL}/recuperarListaOpcaoExtra`, {}).pipe(
      this.mapError
    );
  }

  recuperarListaTipoPesquisaEnum(): Observable<PesquisaUsuarioDto> {
    return this.http.post<PesquisaUsuarioDto>(`${this.baseURL}/recuperarListaTipoPesquisaEnum`, {}).pipe(
      this.mapError
    );
  }

  recuperarListaTipoPesquisaCaixaUploadEnum(): Observable<PesquisaUsuarioCaixaUploadDto> {
    return this.http.post<PesquisaUsuarioCaixaUploadDto>(`${this.baseURL}/recuperarListaTipoPesquisaCaixaUploadEnum`, {}).pipe(
      this.mapError
    );
  }

  recuperarListaPalavraExcluidaDeclaracao(): Observable<PalavraExcluidaDeclaracaoDto> {
    return this.http.post<PalavraExcluidaDeclaracaoDto>(`${this.baseURL}/recuperarListaPalavraExcluidaDeclaracao`, {}).pipe(
      this.mapError
    );
  }

  recuperarCupom(palavra: string, envio: EnvioModel): Observable<CupomDto> {
    return this.http.post<CupomDto>(`${this.baseURL}/recuperarCupom/${palavra}`, envio);
  }

  converterMoeda(moeda: string): Observable<MoedaDto> {
    return this.http.post<MoedaDto>(`${this.baseURL}/converterMoeda`, moeda).pipe(
      this.mapError
    );
  }

  converterMoedaPaises(moeda: string): Observable<MoedaPaisDto> {
    return this.http.post<MoedaPaisDto>(`${this.baseURL}/converterMoedaPais`, moeda).pipe(
      this.mapError
    );
  }

  consultaCep(cep: string): Observable<ViaCepVo> {
    if (!cep) {
      return of();
    }

    return this.http.get<ViaCepVo>(`https://viacep.com.br/ws/${cep.replace('-', '')}/json/`);
  }

  listarImpressoras(): Observable<string[]> {
    return this.http.post<string[]>(`${this.baseURL}/listarImpressoras`, {}).pipe(
      this.mapError
    );
  }

  recuperarListaTipoAlertaEnum(): Observable<DTOModel<TipoAlertaDto>> {
    return this.http.get<DTOModel<TipoAlertaDto>>(`${this.baseURL}/recuperarListaTipoAlertaEnum`).pipe(
      this.mapError
    );
  }
}
